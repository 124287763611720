import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { TixGetConfigurationItemByGroupItemGQL } from '@tix/data-access';
import { TixConfigurationItemGroup } from '@tix/shared/state';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type InputControl = {
  [key: string]: FormControl;
};

type InputValue = {
  [key: string]: string | boolean | undefined;
};

const ignoredConfigurationItems = ['0f621bc0-555a-419b-8a23-fdeed2127030'];

@Component({
  selector: 'tix-form-general-additionnal-config',
  templateUrl: './form-general-additionnal-config.component.html',
  styleUrls: ['./form-general-additionnal-config.component.scss']
})
export class FormGeneralAdditionnalConfigComponent
  implements OnInit, OnDestroy
{
  @Input() eventInstanceId: string | null;
  @Input() userId: Observable<string | undefined>;
  @Input() groupType: string = '';
  @Input() allInputValues: InputValue = {};
  @Output() sendData: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  emailSubscriptionItemIds = [
    '3e95fd19-00e5-474a-b49c-5fe4db4b4b46',
    '14aca7ec-e0e1-4a39-bf8f-cc94ed87a085',
    'e8a7c462-a8c2-4710-aaec-27c60adcaaf7',
    'ce51db08-abd7-4b16-adf3-c5501afb7998'
  ];

  EventManagmentGroupId = 'a05dd91c-b288-4548-b9bf-0e8f1868f9ba';
  emailAdressNotificationId = '7ee7c714-be18-4922-b271-e3b1c60a9719';
  checkBoxEmailNotificationId = 'e0cba607-adcb-4337-9296-08a5e8e25126';

  sendDataToParent() {
    this.sendData.emit(this.configurationForm);
  }

  isEmailSubscriptionitemGroup(id: string) {
    return this.emailSubscriptionItemIds.includes(id);
  }
  castBoolToString(s: string | boolean | undefined | null) {
    if (s === 'false' || s === 'False') {
      return false;
    }

    return true;
  }

  subHeaders: string[] = [];
  allInputsControls: InputControl;

  configurationForm = new FormGroup({});
  sub: Subscription;
  configItems$: Observable<any>;
  sortedData: TixConfigurationItemGroup[];
  isSaving = false;

  constructor(
    private getConfigurationGroupItemsQuery: TixGetConfigurationItemByGroupItemGQL,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.configItems$ = this.getConfigurationGroupItemsQuery
      .fetch({
        groupType: this.groupType
      })
      .pipe(
        map(res => ({
          ...res,
          data: {
            ...res.data,
            ConfigurationItem: res.data.ConfigurationItem.filter(
              item =>
                !ignoredConfigurationItems.includes(item.configurationItemId)
            )
          }
        }))
      );

    this.sub = this.configItems$.subscribe((data: any) => {
      this.sortedData = [...data?.data.ConfigurationItem];
      this.sortedData.sort(
        (a: TixConfigurationItemGroup, b: TixConfigurationItemGroup) => {
          const nameComparison = a.GroupItem?.Group?.name.localeCompare(
            b?.GroupItem?.Group?.name || ''
          );

          return nameComparison === 0
            ? (a?.GroupItem?.viewOrder ?? 0) - (b?.GroupItem?.viewOrder ?? 0)
            : nameComparison || 0;
        }
      );

      for (let item of this.sortedData) {
        if (this.emailSubscriptionItemIds.includes(item.configurationItemId)) {
          continue;
        }
        if (item.configurationItemId != undefined) {
          if (item.elementType === 'Boolean') {
            this.allInputsControls = {
              ...this.allInputsControls,
              [item.configurationItemId]: new FormControl(
                !this.allInputValues[item.configurationItemId]
                  ? this.castBoolToString(item.defaultValue)
                  : this.castBoolToString(
                      this.allInputValues[item.configurationItemId]
                    ),
                [
                  item.required
                    ? Validators.pattern('^(true)$')
                    : Validators.nullValidator
                ]
              )
            };

            continue;
          }
          if (item.regex) {
            this.allInputsControls = {
              ...this.allInputsControls,
              [item.configurationItemId]: new FormControl(
                this.allInputValues[item.configurationItemId] ||
                  item.defaultValue ||
                  '',
                [
                  Validators.pattern(item.regex),
                  item.required ? Validators.required : Validators.nullValidator
                ]
              )
            };
            continue;
          }

          this.allInputsControls = {
            ...this.allInputsControls,
            [item.configurationItemId]: new FormControl(
              this.allInputValues[item.configurationItemId] ||
                item.defaultValue ||
                '',
              [item.required ? Validators.required : Validators.nullValidator]
            )
          };
        }
      }

      this.configurationForm = new FormGroup(this.allInputsControls);

      this.sendDataToParent();
    });

    this.configurationForm.markAsPristine();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.subHeaders = [];
  }

  async onSubmit(e: SubmitEvent) {
    e.preventDefault();

    this.isSaving = false;
  }
}

import { Injectable } from '@angular/core';
import {
  TixBuyerProductFragmentFragment,
  TixGetDiscountInfoQuery
} from '@tix/data-access';
import { TicketConfiguration } from '@tix/event-buyer/state';

export type OrderTicket = TicketConfiguration & {
  rawPrice?: number;
  ticketQty: number;
  discountAmount: number | null;
};
export type OrderProduct = TixBuyerProductFragmentFragment & {
  quantity: number;
};

export type CheckoutOrderInfo = {
  tickets: OrderTicket[];
  products: OrderProduct[];
  isGroup?: boolean;
  groupCode?: string;
  discount?: TixGetDiscountInfoQuery['Discount'][0];
  total: string;
  eventInstanceId: string;
};

const STORAGE_ORDER_KEY = 'checkoutOrderInfo';

@Injectable({
  providedIn: 'root'
})
export class PortalCheckoutService {
  persistOrderInfo(order: CheckoutOrderInfo): void {
    sessionStorage.setItem(STORAGE_ORDER_KEY, JSON.stringify(order));
  }

  getPersistedOrderInfo(): CheckoutOrderInfo | undefined {
    const saved = sessionStorage.getItem(STORAGE_ORDER_KEY);

    if (!saved) return;

    try {
      const order = JSON.parse(saved) as CheckoutOrderInfo;
      return order;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  clearPersistedOrderInfo() {
    sessionStorage.removeItem(STORAGE_ORDER_KEY);
  }
}

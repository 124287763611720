import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'apps/purchasing-portal/src/environments/environment';
import { AuthRedirectService } from './auth-redirect.service';
import { TixAuthService } from './auth.service';

type ReleaseResponse = {
  correctRelease: null | {
    name: string;
    id: string;
    url: string;
  };
};

@Injectable()
export class ReleaseRedirectService {
  private readonly releaseApiUrl = `${environment.releaseApiUrl}/api/releases`;

  constructor(
    private http: HttpClient,
    private authRedirectService: AuthRedirectService,
    private authService: TixAuthService
  ) {}

  check(companyId: string): Observable<boolean> {
    if (environment.disableDynamicReleaseRedirect) return of(false);

    return this.http
      .get<ReleaseResponse>(`${this.releaseApiUrl}/${companyId}`)
      .pipe(
        map(response => {
          if (!response?.correctRelease) return false;

          const release = response.correctRelease;

          const currentHost = window.location.host;
          const newHost = release.url
            .replace('https://', '')
            .replace('http://', '');

          if (newHost === currentHost) return false;

          return true;
        }),
        catchError(error => {
          console.error('Failed execute the release redirection check', error);

          return of(false);
        })
      );
  }

  checkAndRedirect(
    companyId: string,
    shouldAuthenticate = true
  ): Observable<{ willRedirect: boolean }> {
    if (environment.disableDynamicReleaseRedirect)
      return of({ willRedirect: false });

    return this.http
      .get<ReleaseResponse>(`${this.releaseApiUrl}/${companyId}`)
      .pipe(
        switchMap(response => {
          return this.authService.loadUser().pipe(
            take(1),
            map(user => ({
              user,
              response
            }))
          );
        }),
        map(({ response, user }) => {
          if (!response?.correctRelease) return { willRedirect: false };

          const release = response.correctRelease;

          const currentHost = window.location.host;
          const newHost = release.url
            .replace('https://', '')
            .replace('http://', '');

          if (newHost === currentHost) return { willRedirect: false };

          const baseUrl = 'https://' + newHost;

          if (!user || !shouldAuthenticate) {
            // We can execute a regular redirection
            const newUrl = new URL(baseUrl);
            newUrl.pathname = window.location.pathname;
            newUrl.search = window.location.search;

            window.location.href = newUrl.toString();
          } else {
            this.authRedirectService.redirectTo(
              baseUrl,
              window.location.pathname
            );
          }

          return { willRedirect: true };
        }),
        catchError(error => {
          console.error('Failed execute the release redirection check', error);

          return of({ willRedirect: false });
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import {
  TixDecrementDiscountLimitGQL,
  TixGetVenueByPrimaryKeyGQL,
  TixInsertPaymentReservationTicketGQL,
  TixPayment,
  TixPaymentInsertInput,
  TixReservationPayment,
  TixSendETicketGQL,
  TixTicketReservationDetailInsertGQL,
  TixSendTicketPurchaseEmailGQL,
  TixUpdatePaymentGQL,
  TixUpdatePaymentIntentMetadataGQL,
  TixVenue
} from '@tix/data-access';
import { createReservationData } from '@tix/event-buyer/components';
import {
  CustomerService,
  PayoutService,
  TixHiveService
} from '@tix/event-buyer/services';
import { TixFeesService, formatAsDollarFigure } from '@tix/shared/utils';

import { flatten } from 'lodash';
import { map, tap, take } from 'rxjs/operators';
import * as BuyerActions from '../actions';
import { OrderService } from '../services';
@Injectable()
export class EventBuyerEffects {
  getVenueInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerActions.getVenueInfo),
      fetch({
        run: ({ venueId }) => {
          return this.getVenueInfo
            .fetch({ venueId: venueId }, { fetchPolicy: 'no-cache' })
            .pipe(
              map(({ data }) => {
                return BuyerActions.getVenueInfoSuccess({
                  VenueInfo: data.VenueByPK as TixVenue
                });
              })
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return BuyerActions.getVenueInfoFailure({ error });
        }
      })
    )
  );

  insertPaymentReservationTickets = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerActions.insertPaymentReservationTicket),
      fetch({
        run: ({
          logToHive,
          paymentIntent,
          paymentReservationObj,
          venueId,
          orderData,
          reload,
          amountInfo,
          form,
          checkCustomerEmail,
          products
        }) => {
          const { tickets, groupCode, isGroup } = orderData;
          const reservationProcessingFee =
            amountInfo.totalProcessingFee -
            amountInfo.orderItemsProcessingFee -
            amountInfo.donationProcessingFee;

          const reservationDiscountAmount = tickets.reduce((total, ticket) => {
            return total + (ticket.discountAmount || 0);
          }, 0);

          const updatedTicketConfigData = tickets.map((ticket: any) => ({
            ...ticket,
            ticketPrice: ticket.rawPrice ?? ticket.ticketPrice,
            groupCode: groupCode,
            isGroup: isGroup
          }));

          const ticketsWithQuantity = updatedTicketConfigData.map(
            (ticket: any) => {
              if (ticket.ticketQty > 0) {
                return {
                  ...ticket,
                  eventInstanceId: orderData.eventInstanceId,
                  discountAmount: ticket.discountAmount
                };
              } else return ticket;
            }
          );

          const insertPaymentData: TixPaymentInsertInput = {
            ...paymentReservationObj,
            Reservation: {
              data: {
                ...createReservationData(
                  ticketsWithQuantity,
                  paymentReservationObj?.Contact
                ).data,
                reservation: {
                  data: {
                    ...createReservationData(
                      ticketsWithQuantity,
                      paymentReservationObj?.Contact
                    ).data.reservation?.data,
                    agreeToTerms: true,
                    totalAmountPaid: formatAsDollarFigure(
                      amountInfo.totalAmountPaid
                    ),
                    subTotal:
                      amountInfo.totalSubtotal - amountInfo.orderItemsSubtotal,
                    processingFee: formatAsDollarFigure(
                      reservationProcessingFee
                    ),
                    discountId: amountInfo.discountId,
                    discountAmount: formatAsDollarFigure(
                      reservationDiscountAmount
                    ),
                    platformFee: amountInfo.platformFee,
                    venueFee: amountInfo.venueFee,
                    reservation_detail: {
                      data:
                        paymentReservationObj?.Reservation?.data.reservation
                          ?.data.reservation_detail?.data || []
                    }
                  }
                }
              }
            }
          };

          return this.insertPaymentReservationTicket
            .mutate({ insertPayment: insertPaymentData })
            .pipe(
              tap(({ data }) => {
                const reservationTicketOfPayment =
                  data?.InsertPayment?.returning[0];

                if (logToHive) {
                  this.hiveService.logOrder({
                    eventId:
                      reservationTicketOfPayment?.Reservation?.reservation
                        ?.eventInstanceId,
                    orderId:
                      reservationTicketOfPayment?.Reservation?.reservation
                        ?.reservationId,

                    totalPaid: amountInfo.totalAmountPaid, // to review
                    venueId: venueId
                  });
                }
              }),
              map(({ data }) => {
                const reservationTicketOfPayment =
                  data?.InsertPayment?.returning[0];

                if (reservationTicketOfPayment) {
                  // start addons
                  const reservationInfo =
                    reservationTicketOfPayment.Reservation?.reservation;

                  this.updatePayment
                    .mutate({
                      paymentObject: {
                        createdBy: reservationInfo?.contactId
                      },
                      reservationId: reservationInfo?.reservationId
                    })
                    .toPromise()
                    .catch(console.error);

                  this._orderService.orderedProducts(products, {
                    reservationId: reservationInfo?.reservationId,
                    contactId: reservationInfo?.contactId,
                    orderItemsProcessingFee: amountInfo.orderItemsProcessingFee,
                    totalTax: amountInfo.totalTax
                  });
                  // end addons

                  this.sendEmailNotificationMutation
                    .mutate({
                      data: {
                        reservationId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.reservationId,
                        eventInstanceId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.eventInstanceId
                      }
                    })
                    .toPromise()
                    .catch(console.error);

                  this.payoutService.createPayoutDetail({
                    reservationId:
                      reservationTicketOfPayment.Reservation?.reservation
                        ?.reservationId,
                    eventInstanceId:
                      reservationTicketOfPayment.Reservation?.reservation
                        ?.eventInstanceId
                  });

                  this.sendETicket
                    .mutate({
                      data: {
                        baseURL: window.location.origin + '/check-ticket',
                        reservationId:
                          reservationTicketOfPayment.Reservation?.reservation
                            ?.reservationId
                      }
                    })
                    .subscribe(() => {
                      const reservationId =
                        reservationTicketOfPayment.Reservation?.reservation
                          ?.reservationId;
                      this.router
                        .navigate(['/success', venueId, reservationId])
                        .then(() => {
                          // if (reload) window.location.reload();
                        });
                    });

                  if (paymentIntent) {
                    this.updatePaymentIntentMetadataMutation
                      .mutate({
                        mode: paymentIntent.livemode ? 'live' : 'test',
                        paymentIntentId: paymentIntent.id,
                        metadataJson: JSON.stringify({
                          contact_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.contactId,
                          event_instance_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.eventInstanceId,
                          reservation_id:
                            reservationTicketOfPayment.Reservation?.reservation
                              ?.reservationId
                        })
                      })
                      .toPromise()
                      .catch(console.error);
                  }
                  if (!checkCustomerEmail.length) {
                    const newCustomer = this.customerService.updateCustomer({
                      ...form,
                      contactId:
                        reservationTicketOfPayment.Reservation?.reservation
                          ?.contactId
                    });
                  }
                  return BuyerActions.insertPaymentReservationTicketSuccess({
                    paymentObj: reservationTicketOfPayment as TixPayment
                  });
                }
                return BuyerActions.insertPaymentReservationTicketFailure({
                  error: 'Records not found!'
                });
              })
            );
        },
        onError: (action, error) => {
          console.error(error);
          // we don't need to undo the changes on the client side.
          // we can dispatch an error, or simply log the error here and return `null`
          return BuyerActions.insertPaymentReservationTicketFailure({ error });
        }
      })
    )
  );

  constructor(
    // products/addons
    private _orderService: OrderService,
    //
    private feesService: TixFeesService,
    private hiveService: TixHiveService,
    private actions$: Actions,
    private readonly getVenueInfo: TixGetVenueByPrimaryKeyGQL,
    private readonly insertPaymentReservationTicket: TixInsertPaymentReservationTicketGQL,
    private readonly sendETicket: TixSendETicketGQL,
    private readonly store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private sendEmailNotificationMutation: TixSendTicketPurchaseEmailGQL,
    private insertReservationDetailsMuttaion: TixTicketReservationDetailInsertGQL,
    private updatePaymentIntentMetadataMutation: TixUpdatePaymentIntentMetadataGQL,
    private decrementDiscountLimit: TixDecrementDiscountLimitGQL,
    private updatePayment: TixUpdatePaymentGQL,

    private payoutService: PayoutService
  ) {}
}
